<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-syringe"></i> Vaccins
    </h3>
    <div class="text-xl font-semibold text-blackdok mb-5">
      Êtes-vous vacciné ?
    </div>
    <div>
      <div class="flex justify-end">
        <table class="table-auto lg:w-1/2">
          <thead>
            <tr>
              <th class="w-1/2">
                <multiselect
                  v-model="selectedVaccin"
                  :options="vaccins"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  :multiple="false"
                  placeholder="Sélectionnez un vaccin"
                  :class="error ? 'has-error' : ''"
                  selectLabel=""
                  :max="3"
                ></multiselect>
              </th>
              <th class="w-5/12">
                <datepicker
                  v-model="vaccinDate"
                  placeholder="Date de vaccination"
                  :language="dateLangFR"
                  class=""
                  input-class="bg-white  appearance-none border border-solid inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-dokBlue-ultra"
                  :format="customFormatter"
                ></datepicker>
              </th>
              <th style="width:35px;">
                <button
                  v-if="selectedVaccin"
                  @click="saveVaccin"
                  class="bg-transparent border border-dokBlue-ultra py-2 px-2 rounded-cu text-dokBlue-ultra cursor-pointer"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(pVac, indx) in patientVaccins"
              :key="indx"
              class="vaccin-line"
            >
              <td
                class="border-0 border-b border-solid border-gray-300 pl-4 pr-1 py-2 relative"
              >
                {{ pVac.name }}
              </td>
              <td
                class="border-0 border-b border-solid border-gray-300 pl-4 pr-1 py-2 relative"
              >
                {{
                  pVac.vaccinDate && moment()(pVac.vaccinDate).isValid()
                    ? moment()(pVac.vaccinDate).format("D MMM YYYY")
                    : "-"
                }}
                <button
                  @click="removeVaccin(pVac._id)"
                  class="rmv-vaccin-btn absolute bg-transparent text-red-600 border-0 border-red-400 rounded-cu text-white cursor-pointer"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { remove } from "lodash";
import moment from "moment";

export default {
  props: ["patient"],
  components: { Datepicker },
  data() {
    return {
      error: "",
      vaccins: [],
      dateLangFR: fr,
      selectedVaccin: null,
      vaccinDate: null,
      patientVaccins: []
    };
  },
  mounted() {
    var el = this;
    this.$store
      .dispatch("healthbook/GET_PATIENT_VACCIN_LIST", {
        // selected list
        patientId: this.patient._id
      })
      .then(function(response) {
        el.patientVaccins = response.data;
      });

    this.$store
      .dispatch("healthbook/GET_VACCIN_LIST", {
        // All list
        patientId: this.patient._id
      })
      .then(function(response) {
        el.vaccins = response.data;
      });
  },
  methods: {
    saveVaccin() {
      // vaccin.isChecked = $event.target.checked
      this.$store.dispatch("healthbook/SAVE_PATIENT_VACCIN", {
        patientId: this.patient._id,
        data: {
          vaccin: this.selectedVaccin._id,
          name: this.selectedVaccin.name,
          vaccinDate: this.vaccinDate
        },
        onData: this.onDataSave
      });
    },
    removeVaccin(vacinId) {
      this.$store.dispatch("healthbook/REMOVE_PATIENT_VACCIN", {
        patientId: this.patient._id,
        vaccinId: vacinId,
        onData: this.onDataDelete
      });
    },
    onDataSave(data) {
      if (data.ok) this.patientVaccins.push(data.data);
    },
    onDataDelete(vacinId, data) {
      if (data.ok) {
        remove(this.patientVaccins, function(o) {
          return o._id.toString() == vacinId.toString();
        });
      }
    },
    moment() {
      return moment;
    },
    customFormatter(date) {
      this.vaccinDate = moment(date).format("YYYY-MM-DD");
      return moment(date).format("DD MMM YYYY");
    }
  }
};
</script>

<style lang="scss">
.rmv-vaccin-btn {
  display: none;
}
.vaccin-line:hover {
  // background-color: #EEEEEE38;
  .rmv-vaccin-btn {
    position: absolute;
    top: calc(50% - 7.6px);
    right: 0px;
    display: inline-block;
  }
}
</style>
